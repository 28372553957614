import { FC } from 'react';
import Skeleton from 'react-loading-skeleton';

import styles from './styles.module.scss';

/**
 * @description Archive list Item sceleton
 */
export const ArchiveListItem: FC = () => {
  const count = Array.from('12345');
  return (
    <div className={styles.root}>
      <Skeleton className={styles.archiveTitle} />
      {count.map((item) => (
        <div key={item} className={styles.item}>
          <div className={styles.inner}>
            <Skeleton className={styles.preview} />
            <div className={styles.content}>
              <div className={styles.header}>
                <Skeleton className={styles.title} />
                <div className={styles.link}>
                  <Skeleton width={140} />
                </div>
              </div>
              <Skeleton count={4} />
              <div className={styles.mobileLink}>
                <Skeleton width={140} />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
