import { PureComponent } from 'react';
import cn from 'classnames';
import { ReactSVG } from 'react-svg';

import { icons } from ':constants';

import styles from './styles.module.scss';

interface Props {
  className?: string;
  id?: string;
  name: keyof typeof icons;
  onClick?: () => void;
  onMouseDown?: () => void;
  onMouseUp?: () => void;
  svg?: boolean;
  svgClassName?: string;
}

/**
 * @class
 * @description Default icon component
 */
export class Icon extends PureComponent<Props> {
  public render() {
    const { className, name, onClick, onMouseDown, onMouseUp, svg, id, svgClassName, ...rest } = this.props;
    if (!icons[name]) {
      return null;
    }

    const path = icons[name];
    return svg ? (
      <div
        className={cn(styles.icon, className, {
          [styles.clickable]: Boolean(onClick),
        })}
        draggable={false}
        onClick={onClick}
        onKeyDown={onClick}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        role="button"
        tabIndex={0}
      >
        <ReactSVG className={cn(styles.svg, svgClassName)} src={path} {...rest} />
      </div>
    ) : (
      <div
        aria-label={path}
        className={cn(styles.icon, className, {
          [styles.clickable]: Boolean(onClick),
        })}
        draggable={false}
        id={id}
        onClick={onClick}
        onKeyDown={onClick}
        onMouseDown={onMouseDown}
        role="button"
        style={{
          backgroundImage: `url(${path})`,
        }}
        tabIndex={0}
      />
    );
  }
}
