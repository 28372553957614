import { FC } from 'react';
import { Button, IButtonProps, Icons, Typography, Icon } from '@beeline/lk-ui';

import styles from './styles.module.scss';

/**
 * @param root0
 * @param root0.onClick
 * @param root0.title
 * @param root0.iconName
 * @description Button that opens the chat and the questions
 */
export const ChatButton: FC<IButtonProps & { iconName: Icons }> = ({ onClick, title, iconName }) => {
  const icon = <Icon className={styles.icon} iconName={iconName} />;
  return (
    <Button className={styles.openButton} onClick={onClick} size="large" startIcon={icon}>
      <Typography className={styles.label} variant="subtitle2">
        {title}
      </Typography>
    </Button>
  );
};
