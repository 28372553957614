import { FC, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, Card, Button, Typography, TextArea, Select, Option, Checkbox } from '@beeline/lk-ui';

import { RootState } from ':store';

import styles from './styles.module.scss';

import { getEventSpeakers, createEventQuestion } from ':store/event/thunks';
import { ODBackendParticipant } from ':types/od';
import { Image } from ':components/ui/Image';
import allSpeakersAvatar from ':assets/images/allSpeakersAvatar.png';
import defaultAvatar from ':assets/images/avatar.png';

export interface INewQuestionProps {
  onClose: () => void;
  open: boolean;
}

/**
 * @param root0
 * @param root0.open
 * @param root0.onClose
 * @description Component of create new question
 */
export const NewQuestion: FC<INewQuestionProps> = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const { current } = useSelector((state: RootState) => state.event);

  const currentEventID = current?.event_id;
  const allSpeakers: ODBackendParticipant = { fio: 'Всем', photo: allSpeakersAvatar, position: '' };
  const speakers = current?.speakers?.map(({ id, ...props }) => ({ id, value: { ...props } }));
  const speakersOptions = speakers ? [{ id: '$allSpeakers', value: allSpeakers }, ...speakers] : [];

  useEffect(() => {
    if (currentEventID) dispatch(getEventSpeakers(currentEventID));
  }, [dispatch, currentEventID]);

  const [selecteOptions, setOptions] = useState<Option<ODBackendParticipant>[]>([]);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const onSelectItem = (values: Option<ODBackendParticipant>[]) => {
    setOptions(values);
  };

  const [isAnonymous, setAnonymous] = useState<boolean>(false);

  const submitQuestion = () => {
    const message = textAreaRef.current?.value;
    if (currentEventID && message) {
      const selectedId = selecteOptions[0]?.id;
      const speakerId = selectedId !== '$allSpeakers' ? (selectedId as number) : null;
      dispatch(createEventQuestion({ eventId: currentEventID, speakerId, message, isAnonymous }));
    }
  };

  const switchAnonymous = () => setAnonymous((prevValue) => !prevValue);

  const renderValue = (values: Option<ODBackendParticipant>[]) => values[0]?.value?.fio;

  const makeOption = ({ value: { fio, photo, position } }: Option<ODBackendParticipant>) => (
    <div className="speakerOption">
      <Image alt="" className="speakerAvatar" src={photo || defaultAvatar} />
      <div className="speakerInfo">
        <Typography variant="body2">{fio}</Typography>
        <Typography className="speakerPosition" variant="body3">
          {position}
        </Typography>
      </div>
    </div>
  );

  const speakerId = selecteOptions[0]?.id;

  return (
    <Dialog applicationRootElement="eventPopup" className={styles.newQuestion} onClose={onClose} open={open}>
      <Card className={styles.dialogContent}>
        <Typography variant="h5">
          Выберите спикера и напишите ему свой вопрос. После проверки модератором он будет опубликован
        </Typography>
        <Select
          applicationRootElementID="popups"
          fullWidth
          label="Выберите спикера"
          loading={!current?.speakers}
          makeOption={makeOption}
          onChange={onSelectItem}
          options={speakersOptions}
          renderValue={renderValue}
          values={selecteOptions}
        />
        <TextArea ref={textAreaRef} fullWidth label="Вопрос" />
        <Checkbox checked={isAnonymous} label="Анонимный вопрос" onClick={switchAnonymous} />
        <div className={styles.modalFooter}>
          <Button onClick={onClose}>Отмена</Button>
          <Button disabled={!speakerId} onClick={submitQuestion} variant="contained">
            Отправить
          </Button>
        </div>
      </Card>
    </Dialog>
  );
};
