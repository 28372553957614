import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@beeline/lk-ui';

import styles from './styles.module.scss';

import { Icon } from ':components/ui';

/**
 * @description Logo component
 * @returns {ReactNode} Rendered content
 */
export const Logo: FC = () => (
  <Link className={styles.logo} to="/">
    <Typography className={styles.events}>события</Typography>
    <Icon className={styles.icon} name="logo" />
  </Link>
);
