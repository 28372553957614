import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Divider, Typography, Snackbar } from '@beeline/lk-ui';

import { RootState, AppDispatch } from ':store';
import { Question } from '../Question';
import { NewQuestion } from '../NewQuestion';

import styles from './styles.module.scss';

import { watchingQuestions } from ':store/event/thunks';
import { ODBackendQuestion } from ':types/od';
import { setModalVisibility } from ':store/event';

export const Questions: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { current } = useSelector((state: RootState) => state.event);

  const currentEventID = current?.event_id;

  useEffect(() => {
    const promise = currentEventID && dispatch(watchingQuestions(currentEventID));
    return () => {
      if (promise) promise.abort();
    };
  }, [dispatch, currentEventID]);

  const noQuestions = (
    <Typography className={styles.noQuestions} variant="subtitle1">
      Вопросы отсутствуют
    </Typography>
  );

  const openModal = () => dispatch(setModalVisibility(true));
  const closeModal = () => dispatch(setModalVisibility(false));

  const makeQuestion = (question: ODBackendQuestion) => <Question {...question} key={question.id} />;

  const questionsState = current?.questionsState;
  const showModal = !!questionsState?.showModal;
  const showTooltip = !!questionsState?.showTooltip;
  const questions = questionsState?.questions?.map(makeQuestion) || noQuestions;

  return (
    <>
      <div className={styles.questionsBody}>{questions}</div>
      <Divider />
      <div className={styles.footer}>
        <Button onClick={openModal} variant="contained">
          <span>Задать вопрос спикеру</span>
        </Button>
      </div>
      <NewQuestion onClose={closeModal} open={showModal} />
      <Snackbar
        horizontal="center"
        message="Благодарим за ваш вопрос! После проверки модераторами вопрос будет опубликован."
        open={showTooltip}
        variant="elastic"
      />
    </>
  );
};
