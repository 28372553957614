import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import * as thunks from './thunks';

import { AuthStore } from ':types/store';

export const enum PlayerType {
  Unknown,
  Audio = 4,
}

const initialState: AuthStore = {
  user: null,
  translation_url: null, // TODO relocate to event store
  playerType: PlayerType.Unknown,
};

export const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setTranslation(state, action: PayloadAction<{ player_link: string; player_type: number }>) {
      if (action.payload) {
        state.translation_url = action.payload.player_link;
        state.playerType = action.payload.player_type;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(thunks.checkUserAuth.fulfilled, (state, action) => {
      if (action.payload) {
        state.user = action.payload.user;
      }
    });

    // eslint-disable-next-line sonarjs/no-identical-functions
    builder.addCase(thunks.getTranslation.fulfilled, (state, action) => {
      if (action.payload) {
        state.translation_url = action.payload.player_link;
        state.playerType = action.payload.player_type;
      }
    });
  },
});

export const { checkUserAuth, getTranslation, logIn } = thunks;

export const { setTranslation } = auth.actions;

export default auth.reducer;
