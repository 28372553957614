import { createSlice, PayloadAction, isAnyOf } from '@reduxjs/toolkit';

import { metricWatcher } from ':utils';

import * as thunks from './thunks';

import { EventStore } from ':types/store';
import { ODBackendQuestion } from ':types/od';

const initialState: EventStore = {
  current: null,
  preview: null,
  error: null,
};

export const event = createSlice({
  name: 'event',
  initialState,
  reducers: {
    openAuthPage() {
      metricWatcher('auth_start', { method: 'Mobile ID' });
    },
    clickBySpace(_, action: PayloadAction<string>) {
      metricWatcher('go_to_event_space', { title: action.payload });
    },
    /**
     * Sets the visibility of the modal dialog of creating a new question to speaker
     *
     * @param state
     * @param action
     */
    setModalVisibility(state, action: PayloadAction<boolean>) {
      if (state.current) {
        state.current.questionsState.showModal = action.payload;
      }
    },
    /**
     * Sets the tooltip visibility after creating a new question
     *
     * @param state
     * @param action
     */
    setTooltipVisibility(state, action: PayloadAction<boolean>) {
      if (state.current) {
        state.current.questionsState.showTooltip = action.payload;
      }
    },
    /**
     * Sets speakers questions
     *
     * @param state
     * @param action
     */
    setQuestions(state, action: PayloadAction<ODBackendQuestion[]>) {
      if (state.current) {
        state.current.questionsState.questions = action.payload.map((question) => ({
          ...question,
          create_date: new Date(question.create_date).toLocaleTimeString('ru-RU', {
            hour: 'numeric',
            minute: 'numeric',
          }),
        }));
      }
    },
    /**
     * Like speaker question
     *
     * @param state
     * @param action
     */
    likeQuestion(state, action: PayloadAction<number>) {
      if (state.current) {
        const questionID = action.payload;
        const question = state.current.questionsState.questions.find((question) => question.id === questionID);

        if (question) {
          question.user_like = true;
          question.count_like += 1;
        }
      }
    },
    /**
     * Undo like of speaker question
     *
     * @param state
     * @param action
     */
    undoLikeQuestion(state, action: PayloadAction<number>) {
      if (state.current) {
        const questionID = action.payload;
        const question = state.current.questionsState.questions.find((question) => question.id === questionID);

        if (question) {
          question.user_like = false;
          question.count_like -= 1;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(thunks.getCurrentEventPreview.fulfilled, (state, action) => {
      if (action.payload) {
        state.preview = action.payload;
      }
    });

    builder.addCase(thunks.getEventSpeakers.fulfilled, (state, action) => {
      if (action.payload && state.current) {
        state.current.speakers = action.payload;
      }
    });
    builder.addMatcher(isAnyOf(thunks.getEventByID.fulfilled, thunks.getCurrentEvent.fulfilled), (state, action) => {
      if (action.payload) {
        state.current = {
          ...action.payload,
          questionsState: { questions: [], showTooltip: false, showModal: false },
          speakers: [],
        };
        state.error = null;
      }
    });
    builder.addMatcher(isAnyOf(thunks.getEventByID.rejected, thunks.getCurrentEvent.rejected), (state, action) => {
      state.error = action.payload as { status: number };
    });
  },
});

export const { getCurrentEvent, getEventByID } = thunks;
export const {
  openAuthPage,
  clickBySpace,
  setModalVisibility,
  setTooltipVisibility,
  setQuestions,
  likeQuestion,
  undoLikeQuestion,
} = event.actions;

export default event.reducer;
