import { PropsWithChildren, PureComponent } from 'react';
import cn from 'classnames';

import { Footer, Header, PageContainer } from ':components';

import styles from './styles.module.scss';

interface Props {
  className?: string;
  contentClassName?: string;
  hasFooter: boolean;
  hasHeader: boolean;
}

/**
 * @class
 * @description Page component
 */
export class Page extends PureComponent<PropsWithChildren<Props>> {
  public static defaultProps = {
    hasFooter: true,
    hasHeader: true,
  };

  public render() {
    const { children, className, hasFooter, hasHeader, contentClassName } = this.props;
    return (
      <div
        className={cn(
          styles.page,
          { [styles.hasFooterOnly]: hasFooter && !hasHeader, [styles.hasHeaderOnly]: hasHeader && !hasFooter },
          className
        )}
      >
        {hasHeader && <Header />}
        <PageContainer className={styles.container} contentClassName={contentClassName}>
          {children}
        </PageContainer>
        {hasFooter && <Footer />}
      </div>
    );
  }
}
