import { PureComponent } from 'react';
import cn from 'classnames';
import { Typography } from '@beeline/lk-ui';

import { Image } from ':components';

import styles from './styles.module.scss';

import defaultAvatar from ':assets/images/avatar.png';

interface Props {
  avatar?: string | null;
  className?: string;
  eventRole?: string;
  name: string;
  position?: string;
  small?: boolean;
  verticalAlign?: 'top' | 'center';
}

/**
 * @class
 * @description Participant component
 */
export class Participant extends PureComponent<Props> {
  public render() {
    const { avatar, className, eventRole, name, position, small, verticalAlign = 'top' } = this.props;
    const image = avatar || defaultAvatar;

    if (!name) {
      return null;
    }

    const infoAlignStyles = styles[`${verticalAlign}Align`];

    return (
      <div className={cn(styles.participant, { [styles.small]: small }, className)}>
        <Image alt="participant" className={styles.image} src={image} />
        <div className={cn(styles.info, infoAlignStyles)}>
          {eventRole && (
            <Typography className={cn(styles.text, styles.role)} variant="subtitle3">
              {eventRole}
            </Typography>
          )}
          <Typography className={styles.text} variant="subtitle1">
            {name}
          </Typography>
          <Typography className={styles.text} variant="subtitle2">
            {position}
          </Typography>
        </div>
      </div>
    );
  }
}
