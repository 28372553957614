import { FC, useEffect, useState, useCallback } from 'react';

import styles from './styles.module.scss';

export interface IPlayerProps {
  containerRef?: React.RefObject<HTMLDivElement>;
  playerURL: string;
}

export const Player: FC<IPlayerProps> = ({ containerRef, playerURL }) => {
  const [size, setSize] = useState({ width: 0, height: 0 });

  const getSize = useCallback(() => {
    const containerElement = containerRef?.current;

    if (!containerElement) return { width: 0, height: 0 };

    const containerRect = containerElement.getBoundingClientRect();

    const videoRatio = 1.8;
    const videoSize = { width: containerRect.width, height: containerRect.width / videoRatio };

    if (videoSize.height <= containerRect.height) return videoSize;

    return { width: containerRect.height * videoRatio, height: containerRect.height };
  }, [containerRef]);

  useEffect(() => {
    const containerElement = containerRef?.current;

    const resizeHadler = () => setSize(getSize());
    resizeHadler();

    const observer = new ResizeObserver(resizeHadler);
    observer.observe(containerElement as Element);

    return () => {
      observer.disconnect();
    };
  }, [playerURL, containerRef, getSize]);

  return (
    <iframe
      allow="fullscreen; geolocation; encrypted-media; autoplay"
      className={styles.iframe}
      height={size.height}
      id="beelineFrame"
      scrolling="no"
      src={playerURL}
      title="Beeline player"
      width={size.width}
    />
  );
};
