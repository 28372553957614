import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Divider, Typography } from '@beeline/lk-ui';
import { ReactSVG } from 'react-svg';

import { Image } from ':components';

import styles from './styles.module.scss';

import { likeQuestion, undoLikeQuestion } from ':store/event/thunks';
import avatarSVG from ':assets/icons/avatar.svg';
import favorite from ':assets/icons/favorite.svg';
import favoriteBorder from ':assets/icons/favorite-border.svg';
import { ODBackendQuestion, ODBackendQuestioner } from ':types/od';

/**
 * @param root0
 * @param root0.name
 * @param root0.speaker
 * @param root0.id
 * @param root0.count_like
 * @param root0.user_like
 * @param root0.author
 * @param root0.create_date
 * @description Component of question
 */
export const Question: FC<ODBackendQuestion> = ({ name, speaker, id, count_like, user_like, author, create_date }) => {
  const dispatch = useDispatch();

  const favoriteAction = user_like ? undoLikeQuestion : likeQuestion;
  const favoriteOnClick = () => dispatch(favoriteAction(id));

  const avatar = author?.photo ? (
    <Image className={styles.avatar} src={author?.photo || ''} />
  ) : (
    <ReactSVG src={avatarSVG} />
  );
  const makeAuthorName = ({ last_name, first_name }: ODBackendQuestioner) =>
    last_name ? `${first_name} ${last_name}` : first_name;

  return (
    <div className={styles.question}>
      <div className={styles.header}>
        <div className={styles.questionHeader}>
          {avatar}
          <Typography className={styles.nameQuestioner} variant="subtitle2">
            {author ? makeAuthorName(author) : 'Анонимный вопрос'}
          </Typography>
        </div>
        <Typography className={styles.time} variant="caption">
          {create_date}
        </Typography>
      </div>
      <div className={styles.questionAddressee}>
        <Typography className={styles.whom} variant="body2">
          Кому:
        </Typography>
        <Typography className={styles.whomName} variant="body2">
          {speaker?.fio || 'Всем'}
        </Typography>
      </div>
      <Typography className={styles.whomName} variant="body2">
        {name}
      </Typography>
      <div className={styles.favoriteBlock}>
        {count_like !== 0 && <Typography variant="caption">{count_like}</Typography>}
        <ReactSVG className={styles.favorite} onClick={favoriteOnClick} src={user_like ? favorite : favoriteBorder} />
      </div>
      <Divider />
    </div>
  );
};
