import 'core-js';
import 'fetch-polyfill';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import { store } from ':store';

import { App } from './App';

import '@beeline/design-tokens/css/iconfont/icons.css';
import '@beeline/design-tokens/css/tokens/globals/fonts.css';

import '@beeline/lk-ui/core/css/index.css';
import '@beeline/lk-ui/core/css/themes/light.css';

import '@beeline/lk-ui/core/css/font-face.css';
import '@beeline/lk-ui/core/css/iconfont.css';
import '@beeline/lk-ui/core/css/globals.css';
import 'react-loading-skeleton/dist/skeleton.css';

import ':theme/reset.scss';
import ':theme/global.scss';

if (!window.location.href.includes('localhost') && window.location.protocol !== 'https:') {
  window.location.replace(`https://${window.location.hostname}`);
}

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
