import { format, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';

/**
 * @description Formats backend date string to given format
 * @param {string} date ISO date string to format
 * @param {string} outputFormat Output format
 * @param {number} timezone Timezone to use as base for formatting
 * @returns {string} Formatted string
 */
export const formatDate = (date: string, outputFormat: string, timezone = 3) => {
  const parsedDate = parseISO(date);
  parsedDate.setHours(parsedDate.getUTCHours() + timezone);
  return format(parsedDate, outputFormat, { locale: ru });
};

export const metricWatcher = (event: string, method?: { [key: string]: string }) => {
  if (ym) {
    ym(89441137, 'reachGoal', event, method);
  }
};

export const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min);
};
