import { FC, ReactElement, ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { auth } from ':api';
import { RootState } from ':store';

import { checkUserAuth, logIn } from ':store/auth';
import { useYourBrowserStinks } from ':hooks/useYourBrowserStinks';

/**
 * @description component props
 * @interface
 */
interface Props {
  component: ReactElement;
}

/**
 * @param {Props} root0 Component props
 * @param {ReactNode} root0.component Component to render
 * @description Auth component
 */
export const Auth: FC<Props> = ({ component }) => {
  const dispatch = useDispatch();
  const authState = useSelector((state: RootState) => state.auth);
  const browserNotValid = useYourBrowserStinks();
  const navigate = useNavigate();

  useEffect(() => {
    if (browserNotValid) {
      navigate('/');
      return;
    }

    if (auth.hasErrorParam()) {
      navigate(`/${window.location.search}`);
      return;
    }

    dispatch(checkUserAuth());
  }, [browserNotValid, component, dispatch, navigate]);

  useEffect(() => {
    if (!authState.user && !auth.getAccessToken() && !auth.hasNecessaryParams()) {
      dispatch(logIn());
    }
  }, [authState.user, dispatch]);

  return authState.user ? component : null;
};
