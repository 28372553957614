import { FC, useEffect, useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import { ReactSVG } from 'react-svg';
import { Icons } from '@beeline/lk-ui';

import { ChatButton } from '../ChatButton';
import { BroadcastingPopup } from '../EventPopups';
import { Player } from '../Player';

import styles from './styles.module.scss';

import logo from ':assets/icons/close.svg';
import { PlayerType } from ':store/auth';
import { AudioPlayer } from ':components/ui/AudioPlayer';

/**
 * @description component props
 * @interface
 */
interface Props {
  audioPlayerBackground: string;
  onClose: () => void;
  open: boolean;
  playerType?: PlayerType;
  playerURL: string | null;
}

/**
 * @param root0
 * @param root0.open
 * @param root0.playerURL
 * @param root0.onClose
 * @param root0.playerType
 * @param root0.audioPlayerBackground
 * @description Modal component
 */
export const Modal: FC<Props> = ({ open, playerURL, playerType, onClose, audioPlayerBackground }) => {
  const [showChat, setVisibleChat] = useState(false);
  const openChat = () => setVisibleChat(true);
  const closeChat = () => setVisibleChat(false);

  const playerContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!open) closeChat();
  }, [open]);

  const node = document.getElementById('popups');

  if (!open || !node) return null;

  let renderPlayer = null;

  if (playerURL) {
    if (playerType === PlayerType.Audio) {
      renderPlayer = <AudioPlayer backgroundImage={audioPlayerBackground} url={playerURL} />;
    } else {
      renderPlayer = <Player containerRef={playerContainer} playerURL={playerURL} />;
    }
  }

  return createPortal(
    <div className={styles.root}>
      <div aria-label="Закрыть" className={styles.close} onClick={onClose} role="button" tabIndex={0}>
        <ReactSVG src={logo} />
      </div>
      <div className={styles.inner}>
        <div ref={playerContainer} className={styles.player}>
          {renderPlayer}
        </div>
        {showChat && <BroadcastingPopup className={styles.popup} onClose={closeChat} />}
      </div>
      {!showChat && <ChatButton iconName={Icons.Chat} onClick={openChat} title="Чат и вопросы" />}
    </div>,
    node
  );
};
