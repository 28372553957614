import { FC } from 'react';
import Skeleton from 'react-loading-skeleton';

import styles from './styles.module.scss';

/**
 * @description About list Item sceleton
 */
export const About: FC = () => (
  <div className={styles.root}>
    <Skeleton className={styles.banner} containerClassName={styles.skeleton} />
    <Skeleton className={styles.title} containerClassName={styles.skeleton} />
    <Skeleton className={styles.text} containerClassName={styles.skeleton} />
    <Skeleton className={styles.text} containerClassName={styles.skeleton} />
  </div>
);
