import { FC, ReactNode } from 'react';
import { Typography } from '@beeline/lk-ui';
import cn from 'classnames';

import { PageContainer } from ':components';

import styles from './styles.module.scss';

interface Props {
  absolute?: boolean;
}

/**
 * @param {Props} root0 Element props
 * @param {boolean | undefined} root0.absolute Absolute positioning
 * @description Footer component
 * @returns {ReactNode} Rendered content
 * @function
 */
export const Footer: FC<Props> = ({ absolute }) => (
  <div
    className={cn(styles.footer, {
      [styles.absolute]: absolute,
    })}
  >
    <div className={styles.inner}>
      <PageContainer>
        <Typography className={styles.text} variant="body2">
          В случае возникновения технических вопросов, зарегистрируйте обращение в{' '}
          <a
            className={styles.link}
            href="https://lke.beeline.ru/helpdesk/catalog?dialog=my-request-form&dialog-params=templateId%253A%253DTID000000001898%253A%252B"
            rel="noreferrer"
            target="_blank"
          >
            helpdesk
          </a>
          , выбрав тему #event.beeline.ru. Или позвоните в Help Desk по номеру{' '}
          <a className={styles.link} href="tel:88007700808" rel="noreferrer">
            8(800)770-08-08
          </a>{' '}
          и нажмите 2.
        </Typography>
        <Typography className={styles.text} variant="body3">
          Информация на сайте event.beeline.ru предназначена только для действующих сотрудников ПАО «ВымпелКом».
          Пересылка материалов и ссылок в любой форме, полностью или частично, как внутри компании, так и третьим лицам,
          строго запрещена! билайн бережно относится к внутренней информации — это наш Образ жизни и одна из основ
          доверия в нашей команде.
        </Typography>
      </PageContainer>
    </div>
  </div>
);
