import { PropsWithChildren, PureComponent } from 'react';
import cn from 'classnames';
import { Card, Typography, Button, Icon, Icons } from '@beeline/lk-ui';

import styles from './styles.module.scss';

interface Props {
  className?: string;
  htmlDescription: string;
  openQuestions: VoidFunction;
}

export class AskQuestionBanner extends PureComponent<PropsWithChildren<Props>> {
  public render() {
    const { className, openQuestions, htmlDescription } = this.props;
    return (
      <div className={cn(styles.askQuestionBanner, className)}>
        <Card className={styles.card}>
          <Typography dangerouslySetInnerHTML={{ __html: htmlDescription }} variant="subtitle1" />
          <Button
            className={styles.button}
            onClick={openQuestions}
            size="large"
            startIcon={<Icon iconName={Icons.Chat} />}
            variant="overlay"
          >
            Задать вопрос
          </Button>
        </Card>
      </div>
    );
  }
}
