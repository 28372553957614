import { FC } from 'react';
import cn from 'classnames';
import { Button, Card } from '@beeline/lk-ui';

import { RESPONSE } from ':constants';
import { Image } from ':components';

import styles from './styles.module.scss';

import { useWindowSize } from ':hooks/useWindowSize';

interface Props {
  buttonAlign: 'left' | 'middle' | 'right';
  buttonColor: 'black' | 'yellow';
  className?: string;
  disabled?: boolean;
  hide?: boolean;
  image: string;
  imageMobile: string;
  onOpenClick?: () => void;
  title: string;
}

/**
 * EventCard component
 *
 * @param props
 * @param props.className
 * @param props.disabled
 * @param props.onOpenClick
 * @param props.image
 * @param props.imageMobile
 * @param props.hide
 * @param props.title
 * @param props.buttonAlign
 * @param props.buttonColor
 */
export const EventCard: FC<Props> = ({
  className,
  disabled,
  onOpenClick,
  image,
  imageMobile,
  hide,
  title,
  buttonAlign,
  buttonColor,
}) => {
  const { width } = useWindowSize();
  const mobile = width < RESPONSE.EXTRA_SMALL;
  const contentAlignStyle = styles[`${buttonAlign}Align`];
  const buttonStyle = buttonColor === 'black' ? styles.blackButton : undefined;

  return (
    <Card className={cn(styles.eventCard, className)} elevation="low">
      {mobile && <Image className={styles.image} src={imageMobile} />}
      {!mobile && <Image className={styles.image} src={image} />}
      {!hide && (
        <div className={cn(styles.content, contentAlignStyle)}>
          <div className={styles.controls}>
            <Button className={buttonStyle} disabled={disabled} onClick={onOpenClick} size="large" variant="contained">
              {title}
            </Button>
          </div>
        </div>
      )}
    </Card>
  );
};
