import { useEffect, useState } from 'react';

/**
 * @description window size hook
 * @returns {object} Window size
 */
export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });

  /** @description Handler to call on window resize */
  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    /** Add event listener */
    window.addEventListener('resize', handleResize);

    /**
     * @description Call handler right away so state
     * gets updated with initial window size
     */
    handleResize();

    /**
     * @description Remove event listener on cleanup
     * @returns {void}
     */
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    ...windowSize,
    checkSize: <T = any>(type: 'width' | 'height', ...args: ([T, number | string] | [T])[]) => {
      const a = args.reverse();
      const arg = a.find((item) => (item[1] ? windowSize[type] <= item[1] : true));
      if (arg) {
        return arg[0];
      }

      return a[args.length - 1][0];
    },
  };
}
