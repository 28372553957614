import { ReactNode, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { Card, Typography } from '@beeline/lk-ui';
import { LinkRouter } from '@beeline/lk-ui/core/components/LinkRouter';
import { useLocation } from 'react-router-dom';

import { Icon, Logo } from ':components';

import styles from './styles.module.scss';

const routes = [
  {
    name: 'Анонсы',
    href: '/announces',
  },
  {
    name: 'Архив',
    href: '/archive',
  },
];

/**
 * @function
 * @description Header component
 * @returns {ReactNode} Rendered content
 */
export const Header: FC = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const location = useLocation();

  /**
   * @description Opens menu on Hamburger click
   */
  const onHamburgerClick = useCallback(() => {
    setMenuOpen(true);
  }, []);

  /**
   * @description Closes Menu on outside click
   */
  const onOutsideClick = useCallback(
    (event: MouseEvent) => {
      if (isMenuOpen && ref.current && !ref.current.contains(event.currentTarget as HTMLDivElement)) {
        setMenuOpen(false);
      }
    },
    [isMenuOpen]
  );

  const routeList = useMemo(
    () =>
      routes.map(({ href, name }) => (
        <div key={name} className={cn(styles.route, { [styles.active]: href === location.pathname })}>
          <LinkRouter className={styles.link} href={href} isActive>
            <Typography className={styles.linkText} variant="subtitle3">
              {name}
            </Typography>
          </LinkRouter>
        </div>
      )),
    [location.pathname]
  );

  useEffect(() => {
    document.addEventListener('click', onOutsideClick);

    return () => {
      document.removeEventListener('click', onOutsideClick);
    };
  }, [onOutsideClick]);

  return (
    <div className={cn(styles.header)}>
      <Logo />
      <div ref={ref} className={styles.mobile}>
        <Icon className={styles.hamburger} name="hamburger" onClick={onHamburgerClick} />
        {isMenuOpen && (
          <Card className={styles.menu} elevation="low">
            {routeList}
          </Card>
        )}
      </div>
      <div className={styles.desktop}>{routeList}</div>
    </div>
  );
};
