import logo from '@beeline/design-tokens/assets/logo/logo-light-theme.svg';

import arrowPinkTilted from ':assets/icons/arrow-pink-tilted.svg';
import hamburger from ':assets/icons/hamburger.svg';
import slack from ':assets/icons/slack.svg';
import smilingSun from ':assets/icons/smiling-sun.svg';
import victory from ':assets/icons/victory.svg';
import audioPlayerPlay from ':assets/icons/audioPlayerPlay.svg';
import audioPlayerPause from ':assets/icons/audioPlayerPause.svg';

/**
 * @description SVG icon list
 */
export const icons = {
  arrowPinkTilted,
  hamburger,
  logo,
  slack,
  smilingSun,
  victory,
  audioPlayerPlay,
  audioPlayerPause,
};
