import { createSlice } from '@reduxjs/toolkit';

import * as thunks from './thunks';

import { ArchiveStore } from ':types/store';

const initialState: ArchiveStore = {
  current: null,
};

export const event = createSlice({
  name: 'archive',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(thunks.getArchiveList.fulfilled, (state, action) => {
      if (action.payload) {
        state.current = action.payload;
      }
    });
  },
});

export const { getArchiveList } = thunks;

export default event.reducer;
