import { API } from ':api';
import {
  ODBackendEvent,
  ODBackendEventPublic,
  ODBackendSpeaker,
  ODBackendQuestion,
  ODBackendTranslation,
} from ':types';

export const checkUserInfo = async () => {
  const response = await API.get('/api/v2/user/info/');
  return response.data;
};

export const fetchCurrentEvent = async () => {
  const response = await API.get<ODBackendEvent>('/api/v2/events/active/');
  return response.data;
};

export const fetchEventByID = async (id: string) => {
  const response = await API.get<ODBackendEvent>(`/api/v2/events/${id}/`);
  return response.data;
};

export const fetchCurrentEventPreview = async () => {
  const response = await API.get<ODBackendEventPublic>('/api/v2/events/active/public/');
  return response.data;
};

export const fetchTranslation = async (eventId: number, refresh = false) => {
  const response = await API.get<ODBackendTranslation>(`/api/v2/translation/?event_id=${eventId}`, {
    params: { refresh: refresh || null },
  });
  return response.data;
};

export const fetchQuestions = async (eventId: number) => {
  const response = await API.get<ODBackendQuestion[]>(`/api/v2/questions/?event_id=${eventId}`);
  return response.data;
};

export const fetchSpeakers = async (eventId: number) => {
  const response = await API.get<ODBackendSpeaker[]>(`api/v2/speakers/?event_id=${eventId}`);
  return response.data;
};

export const postQuestion = async (
  eventId: number,
  speakerId: number | null,
  message: string,
  isAnonymous: boolean
) => {
  const response = await API.post('/api/v2/questions', {
    event_id: eventId,
    speaker_id: speakerId,
    name: message,
    anonymous: isAnonymous,
  });
  return response.status;
};

export const postLikeQuestion = async (questionId: number) => {
  const response = await API.post(`/api/v2/questions/${questionId}/like/`);
  return response.status;
};

export const deleteLikeQuestion = async (questionId: number) => {
  const response = await API.delete(`/api/v2/questions/${questionId}/like/`);
  return response.status;
};
