import { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Typography, Button } from '@beeline/lk-ui';

import { Image } from ':components';
import { ODBackendEventDescription } from ':types';

import styles from './styles.module.scss';

import preview from ':assets/images/avatar.png';
import { openAuthPage } from ':store/event';

type Props = ODBackendEventDescription & {
  onClickLink: (title: string) => () => void;
};

/**
 * @param props component props
 * @param props.image preview
 * @param props.name title
 * @param props.description description
 * @param props.onClickLink on click link
 * @param props.event_id
 * @description Archive list Item component
 */
export const AnnouncementListItem: FC<Props> = ({ onClickLink, name, description, image, event_id }) => {
  const dispatch = useDispatch();

  const renderLink = useMemo(() => {
    const handleAuthStart = () => {
      dispatch(openAuthPage());
    };
    return (
      <Link className={styles.link} onClick={handleAuthStart} to={`/announces/${event_id}`}>
        <Button onClick={onClickLink(name)} size="medium" title="Перейти к анонсу" variant="contained">
          Перейти к анонсу
        </Button>
      </Link>
    );
  }, [name, event_id, onClickLink, dispatch]);

  return (
    <div className={styles.root}>
      <div className={styles.inner}>
        <div className={styles.preview}>
          <Image alt={name} src={image || preview} />
        </div>
        <div className={styles.content}>
          <div className={styles.header}>
            <Typography className={styles.title} variant="subtitle1">
              {name}
            </Typography>
          </div>
          <Typography className={styles.text} dangerouslySetInnerHTML={{ __html: description }} variant="body2" />
          {renderLink}
        </div>
      </div>
    </div>
  );
};
