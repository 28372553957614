import { FC, ReactNode } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { HomePage, Event, Archive, Announces } from ':pages';

import { Auth } from ':components/ui/Auth';

/**
 * @description App root component
 * @returns {ReactNode} Rendered content
 */
export const App: FC = () => (
  <BrowserRouter>
    <Routes>
      <Route element={<HomePage />} path="/" />
      <Route element={<Auth component={<Event />} />} path="/about" />
      <Route element={<Auth component={<Archive />} />} path="/archive/" />
      <Route element={<Auth component={<Event />} />} path="/archive/:id" />
      <Route element={<Auth component={<Announces />} />} path="/announces" />
      <Route element={<Auth component={<Event />} />} path="/announces/:id" />
    </Routes>
  </BrowserRouter>
);
