import { FC, ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { Button, Card, Typography } from '@beeline/lk-ui';

import { Logo, Page, Image } from ':components';
import { RootState } from ':store';
import { formatDate } from ':utils';
import { auth } from ':api';

import styles from './styles.module.scss';

import background from ':assets/images/background.png';
import { getCurrentEventPreview } from ':store/event/thunks';
import { openAuthPage } from ':store/event';
import { useYourBrowserStinks } from ':hooks/useYourBrowserStinks';

/**
 * @description HomePage component
 * @returns {ReactNode} Rendered content
 */
export const HomePage: FC = () => {
  const browserNotValid = useYourBrowserStinks();
  const dispatch = useDispatch();
  const { preview } = useSelector((state: RootState) => state.event);
  const hasError = auth.hasErrorParam();

  useEffect(() => {
    dispatch(getCurrentEventPreview());
  }, [dispatch]);

  const handleAuthStart = () => {
    dispatch(openAuthPage());
  };

  return (
    <Page
      className={cn(styles.home, { [styles.ie]: browserNotValid })}
      contentClassName={styles.content}
      hasFooter={!browserNotValid}
      hasHeader={false}
    >
      <Card border="default" className={styles.card}>
        <Logo />
        <Link className={styles.link} onClick={handleAuthStart} to="/about">
          <Button className={cn(styles.button, styles.mobileId)} size="large" variant="contained">
            Перейти к событию
          </Button>
        </Link>
        {browserNotValid && (
          <Typography className={cn(styles.message, styles.ie)} variant="subtitle1">
            Internet Explorer не поддерживает плеер, попробуйте Google Chrome
          </Typography>
        )}
        {hasError && (
          <Typography className={cn(styles.message, styles.error)} variant="subtitle1">
            Не удалось авторизоваться, попробуйте позже
          </Typography>
        )}
        {preview && (
          <>
            <Typography className={styles.name} variant="h4">
              {preview.name}
              <br />
              {preview.start_date && formatDate(preview.start_date, 'dd MMMM в HH:mm (мск)')}
            </Typography>
            {!browserNotValid && preview.zoom_link && (
              <a className={cn(styles.link, styles.zoom)} href={preview.zoom_link} rel="noreferrer" target="_blank">
                <Button className={styles.button} size="large">
                  Смотреть в Zoom
                </Button>
              </a>
            )}
          </>
        )}
      </Card>
      <Image alt="event-background" className={styles.background} src={preview?.banner_auth || background} />
    </Page>
  );
};
