import { PropsWithChildren, PureComponent } from 'react';
import cn from 'classnames';
import { Card, Typography } from '@beeline/lk-ui';

import styles from './styles.module.scss';

interface Props {
  className?: string;
  htmlDescription: string;
}

/**
 * @class
 * @description BubbleInfo component
 */
export class BubbleInfo extends PureComponent<PropsWithChildren<Props>> {
  public render() {
    const { htmlDescription, className } = this.props;
    return (
      <div className={cn(styles.bubbleInfo, className)}>
        <Card className={styles.card}>
          <Typography dangerouslySetInnerHTML={{ __html: htmlDescription }} variant="subtitle1" />
        </Card>
      </div>
    );
  }
}
