import { createAsyncThunk } from '@reduxjs/toolkit';

import { auth, checkUserInfo, fetchTranslation } from ':api';
import { metricWatcher, getRandomInt } from ':utils';

import { setTranslation } from './index';

import { RootState } from ':types/store';

/**
 * @description Check user auth
 */
export const checkUserAuth = createAsyncThunk('auth/checkUserAuth', async () => {
  try {
    const accessToken = auth.getAccessToken();
    const hasNecessaryParams = auth.hasNecessaryParams();
    if (accessToken || hasNecessaryParams) {
      if (hasNecessaryParams) {
        await auth.exchangeCode();
        metricWatcher('auth_success', { method: 'Mobile ID' });
      }

      const res = await checkUserInfo();
      return { user: res };
    }

    return null;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    metricWatcher('auth_failure', { method: 'Mobile ID' });
    return null;
  }
});
/**
 * @description Get frame url
 */
export const getTranslation = createAsyncThunk(
  'auth/getTranslation',
  async (
    {
      id,
      loading,
      modal,
      error,
    }: {
      error: React.Dispatch<React.SetStateAction<boolean>>;
      id: number;
      loading: React.Dispatch<React.SetStateAction<boolean>>;
      modal: React.Dispatch<React.SetStateAction<boolean>>;
    },
    thunksAPI
  ) => {
    const watchTranslation = async (intervalID: number) => {
      if (thunksAPI.signal.aborted) clearInterval(intervalID);

      try {
        const { player_link: newTranslationURL, player_type } = await fetchTranslation(id, true);

        const {
          auth: { translation_url: currentTranslationURL },
        } = thunksAPI.getState() as RootState;

        if (currentTranslationURL !== newTranslationURL) {
          if (document.fullscreenElement) document.exitFullscreen();

          thunksAPI.dispatch(setTranslation({ player_link: newTranslationURL, player_type }));
        }
      } catch (e) {
        // TODO Show error
      }
    };

    try {
      loading(true);
      const translation = await fetchTranslation(id);

      const interval = 60000 + getRandomInt(0, 10) * 1000;

      const intervalID: number = setInterval(async () => watchTranslation(intervalID), interval);

      modal(true);
      return translation;
    } catch (e) {
      error(true);
      setTimeout(() => error(false), 5000);
      return null;
    } finally {
      loading(false);
    }
  }
);
/**
 * @description Login
 */
export const logIn = createAsyncThunk('auth/getTranslation', async () => {
  auth.startAuth({ replaceHistory: true });
});
