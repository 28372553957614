import { Typography, Button } from '@beeline/lk-ui';

import styles from './styles.module.scss';

import emptyImage from ':assets/icons/empty_box@3x.png';
import errorImage from ':assets/icons/check@2x.png';

const errors = {
  0: {
    title: 'Произошла неизвестная ошибка',
    image: errorImage,
    description: '',
  },
  404: {
    title: 'Сейчас нет активного мероприятия',
    image: emptyImage,
    description: '',
  },
};

export const Error = ({ errType }: { errType: keyof typeof errors }) => (
  <div className={styles.errorContainer}>
    <img alt="error" className={styles.errorImage} src={errors[errType].image} />
    <Typography className={styles.errorText} variant="body3">
      {errors[errType].title}
    </Typography>
    <Typography variant="caption">{errors[errType].description}</Typography>
    {errType === 0 && (
      <Button className={styles.errorButton} onClick={() => window.location.reload()} size="small" variant="contained">
        Обновить страницу
      </Button>
    )}
  </div>
);
