import { memo, FC, ReactChildren, ReactNode } from 'react';
import cn from 'classnames';

import styles from './styles.module.scss';

type ColParam = number | 'disabled';
type Child = ReactChildren | Element | ReactNode;

/**
 * @description component row props
 * @interface
 */
interface RowProps {
  children: Child;
  className?: string;
  justify?: 'center' | 'right';
  noMargin?: boolean;
  onClick?: () => void;
  style?: React.CSSProperties;
}

/**
 * @description component col props
 * @interface
 */
interface ColProps {
  children: Child;
  className?: string;
  flex?: boolean;
  justify?: 'center' | 'left' | 'right';
  lg?: ColParam;
  md?: ColParam;
  onClick?: () => void;
  pb?: string | string[];
  pt?: string | string[];
  sl?: ColParam;
  sm?: ColParam;
  style?: React.CSSProperties;
  xs?: ColParam;
}

/**
 * @description Greed row component
 * @param props component props
 * @param props.children chldren elements
 * @param props.justify justify position
 * @param props.style custom styles
 * @param props.customClasses custom classes
 * @param props.onClick click
 */
const Row: FC<RowProps> = memo(({ children, justify, style, className, noMargin, onClick }) => (
  <div
    className={cn(
      styles.row,
      {
        [styles.center]: justify === 'center',
        [styles.right]: justify === 'right',
        [styles.noMargin]: noMargin,
        [styles.clickable]: Boolean(onClick),
      },
      className
    )}
    onClick={onClick}
    role="none"
    style={{ ...style }}
  >
    {children}
  </div>
));

/**
 * @description Greed col component
 * @param props component props
 * @param props.lg large res.
 * @param props.md middle res.
 * @param props.sm small res.
 * @param props.xs extra small res.
 * @param props.children children elements
 * @param props.style custom styles
 * @param props.className custom classes
 * @param props.flex flex
 * @param props.pt padding top
 * @param props.pb padding bottom
 * @param props.onClick click
 */
const Col: FC<ColProps> = memo(({ lg, md, sm, xs, sl, children, style, className, flex, pt, pb, onClick, justify }) => {
  const renderPadding = (prop?: string | string[], key?: 'pt' | 'pb') => {
    if (!prop) return [];
    if (typeof prop === 'string') {
      return [styles[`${key}${prop}`]];
    }
    return [...prop.map((item) => styles[`${key}${item}`])];
  };

  return (
    <div
      className={cn(
        styles.col,
        {
          [styles[`lg${lg}`]]: Boolean(lg),
          [styles[`md${md}`]]: Boolean(md),
          [styles[`sm${sm}`]]: Boolean(sm),
          [styles[`xs${xs}`]]: Boolean(xs),
          [styles[`xs${sl}`]]: Boolean(sl),
          [styles.clickable]: Boolean(onClick),
          [styles.flex]: flex,
        },
        ...renderPadding(pt, 'pt'),
        ...renderPadding(pb, 'pb'),
        styles[`justify-${justify}`],
        className
      )}
      onClick={onClick}
      role="none"
      style={{ ...style }}
    >
      {children}
    </div>
  );
});

Col.defaultProps = {
  lg: 0,
  md: 0,
  sm: 0,
  xs: 0,
  sl: 0,
  justify: 'left',
};

export const Grid = {
  Row,
  Col,
};
