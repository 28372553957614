import { createRef, PureComponent } from 'react';

import { API } from ':api';

interface Props {
  alt?: string;
  className?: string;
  src: string;
}

/**
 * @class
 * @description Image component
 */
export class Image extends PureComponent<Props> {
  private ref = createRef<HTMLImageElement>();

  public async componentDidMount() {
    await this.onLoadRequested();
  }

  public async componentDidUpdate() {
    await this.onLoadRequested();
  }

  public componentWillUnmount() {
    this.onRevokeRequested();
  }

  /**
   * @description Performs url check to load using blob mechanic
   */
  private onLoadRequested = async () => {
    let { src } = this.props;
    const { current: img } = this.ref;

    if (img) {
      if (this.isValidUrl(src)) {
        try {
          const { REACT_APP_LK_API_SERVER_URL } = process.env;
          if (REACT_APP_LK_API_SERVER_URL && REACT_APP_LK_API_SERVER_URL.includes('localhost')) {
            src = `${REACT_APP_LK_API_SERVER_URL}/${src.split('.ru/')[1]}`;
          }
          const { data } = await API.get<Blob>(src, { responseType: 'blob' });
          img.src = URL.createObjectURL(data);
        } catch {
          img.src = '';
        }
        return;
      }

      img.src = src;
    }
  };

  /**
   * @description Clears memory if url check passed
   */
  private onRevokeRequested = () => {
    const { src } = this.props;
    const { current: img } = this.ref;

    if (img?.src && this.isValidUrl(src)) {
      URL.revokeObjectURL(img.src);
    }
  };

  /**
   * @description Checks for url validity
   * @param {string} url Url to check
   * @returns {boolean} Check result
   */
  private isValidUrl = (url: string) => url.startsWith('http://') || url.startsWith('https://');

  public render() {
    const { alt, className } = this.props;
    return <img ref={this.ref} alt={alt} className={className} />;
  }
}
