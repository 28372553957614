import { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Typography } from '@beeline/lk-ui';

import { Image } from ':components';
import { RESPONSE } from ':constants';
import { ODBackendEventDescription } from ':types';

import styles from './styles.module.scss';

import { useWindowSize } from ':hooks/useWindowSize';
import preview from ':assets/images/avatar.png';
import { openAuthPage } from ':store/event';

type Props = ODBackendEventDescription & {
  onClickLink: (title: string) => void;
};

/**
 * @param props component props
 * @param props.image preview
 * @param props.name title
 * @param props.description description
 * @param props.onClickLink on click link
 * @param props.event_id
 * @description Archive list Item component
 */
export const ArchiveListItem: FC<Props> = ({ onClickLink, name, description, image, event_id }) => {
  const { width } = useWindowSize();
  const mobile = width < RESPONSE.SMALL;
  const dispatch = useDispatch();

  const renderLink = useMemo(() => {
    const handleAuthStart = () => {
      dispatch(openAuthPage());
      onClickLink(name);
    };
    return (
      <div className={styles.linkContainer}>
        <Link className={styles.link} onClick={handleAuthStart} to={`/archive/${event_id}`}>
          <Typography variant="subtitle3">Подробнее</Typography>
        </Link>
      </div>
    );
  }, [name, event_id, onClickLink, dispatch]);

  return (
    <div className={styles.root}>
      <div className={styles.inner}>
        <div className={styles.preview}>
          <Image alt={name} src={image || preview} />
        </div>
        <div className={styles.content}>
          <div className={styles.header}>
            <Typography className={styles.title} variant="subtitle1">
              {name}
            </Typography>
            {!mobile && renderLink}
          </div>
          <Typography className={styles.text} dangerouslySetInnerHTML={{ __html: description }} variant="body2" />
          {mobile && renderLink}
        </div>
      </div>
    </div>
  );
};
