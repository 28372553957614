import { PropsWithChildren, PureComponent, ReactNode } from 'react';
import cn from 'classnames';
import { Typography } from '@beeline/lk-ui';
import sanitizeHtml from 'sanitize-html';

import { Participant } from ':components';
import { ODBackendEvent } from ':types';
import { formatDate } from ':utils';

import styles from './styles.module.scss';

interface Props {
  className?: string;
  event: ODBackendEvent;
}

/**
 * @class
 * @description Program component
 */
export class Program extends PureComponent<PropsWithChildren<Props>> {
  /**
   * @description Renders participants list
   * @returns {ReactNode} Rendered content
   */
  public renderList = () => {
    const { event } = this.props;

    return event.topic.map(({ name, description, speaker, time_end, time_start }, index) => (
      <div key={index} className={styles.item}>
        <Typography className={styles.time} variant="h5">
          {formatDate(time_start, 'HH:mm')}-{formatDate(time_end, 'HH:mm')}
        </Typography>
        <div className={styles.block}>
          <Typography variant="h4">{name}</Typography>
          {description && (
            <Typography
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: sanitizeHtml(description) }}
              variant="subtitle1"
            />
          )}
          <div className={styles.speakers}>
            {speaker.map(({ fio, position, photo }) => (
              <Participant
                key={fio}
                avatar={photo}
                className={styles.speaker}
                name={fio}
                position={position}
                verticalAlign="center"
              />
            ))}
          </div>
        </div>
      </div>
    ));
  };

  public render() {
    const {
      className,
      event: { moderator, program_description, program_name, topic },
    } = this.props;

    const isEmptyDescription = !program_name && !program_description && moderator.length === 0 && topic.length === 0;
    if (isEmptyDescription) return null;

    return (
      <div className={cn(styles.program, className)}>
        <Typography className={styles.title} variant="h1">
          {program_name}
        </Typography>
        <Typography
          className={styles.subtitle}
          dangerouslySetInnerHTML={{ __html: sanitizeHtml(program_description) }}
          variant="h2"
        />
        <div className={styles.moderators}>
          {moderator.map(({ fio, photo, position, custom_name }) => (
            <Participant
              key={fio}
              avatar={photo}
              className={styles.moderator}
              eventRole={custom_name || 'Модератор'}
              name={fio}
              position={position}
              small
            />
          ))}
        </div>
        {topic.length > 0 && <div className={styles.list}>{this.renderList()}</div>}
      </div>
    );
  }
}
