import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetchEventArchive } from ':api';

/**
 * @description Gets current event
 */
export const getArchiveList = createAsyncThunk('auth/getArchiveList', async () => {
  try {
    return await fetchEventArchive();
  } catch (e) {
    return null;
  }
});
