import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetchEventAnnounces } from ':api';

/**
 * @description Gets announced events
 */
export const getAnnounceList = createAsyncThunk('auth/getAnnounceList', async () => {
  try {
    return await fetchEventAnnounces();
  } catch (e) {
    return null;
  }
});
