import { FC } from 'react';
import { createPortal } from 'react-dom';
import { IconButton, Icons } from '@beeline/lk-ui';
import cn from 'classnames';

import styles from './styles.module.scss';

export interface IPopupProps {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  id?: string;
  onClose: () => void;
  targetElementId?: string;
}

/**
 * @param root0
 * @param root0.children
 * @param root0.className
 * @param root0.onClose
 * @param root0.targetElementId
 * @param root0.id
 * @description Base implementation of pop up component
 */
export const Popup: FC<IPopupProps> = ({ children, className, onClose, targetElementId = 'popups', id }) => {
  const node = document.getElementById(targetElementId);
  if (!node) return null;
  return createPortal(
    <div className={cn(styles.popup, className)} id={id}>
      {children}
      <IconButton className={styles.closeButton} iconName={Icons.Close} onClick={onClose} size="large" />
    </div>,
    node
  );
};
