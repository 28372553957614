import { FC, useRef, useState, useCallback, useEffect } from 'react';

import { Image } from ':components';

import styles from './styles.module.scss';

import { icons } from ':constants/icons';

export interface IAudioPlayer {
  backgroundImage: string;
  url: string;
}
export const AudioPlayer: FC<IAudioPlayer> = ({ url, backgroundImage }) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isPlayed, setIsPlayed] = useState(false);

  const onPauseEvent = useCallback(() => setIsPlayed(false), [setIsPlayed]);
  const onPlayClick = useCallback(() => {
    if (audioRef.current?.paused) {
      audioRef.current?.play();
    } else {
      audioRef.current?.pause();
    }
  }, [audioRef]);

  return (
    <div className={styles.AudioPlayer}>
      <audio
        ref={audioRef}
        className={styles.HiddenAudio}
        onEnded={onPauseEvent}
        onError={onPauseEvent}
        onPause={onPauseEvent}
        onPlay={() => setIsPlayed(true)}
        src={url}
      />
      <Image className={styles.Image} src={backgroundImage} />;
      <div
        aria-label="Воспроизвести аудио"
        className={styles.PlayButtonContainer}
        onClick={onPlayClick}
        role="button"
        tabIndex={0}
      >
        <div
          className={styles.PlayButton}
          style={{ backgroundImage: `url("${isPlayed ? icons.audioPlayerPause : icons.audioPlayerPlay}")` }}
        />
      </div>
    </div>
  );
};
