import { FC, memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@beeline/lk-ui';

import { RootState } from ':store';
import { Page, Skeleton, AnnouncementListItem } from ':components';

import styles from './styles.module.scss';

import { getAnnounceList } from ':store/announce';
import { clickBySpace } from ':store/event';

/**
 * @description Archive component
 */
export const Announces: FC = memo(() => {
  const dispatch = useDispatch();
  const list = useSelector((state: RootState) => state.announce.current);

  useEffect(() => {
    dispatch(getAnnounceList());
  }, [dispatch]);

  const handleClickSpace = (title: string) => () => {
    dispatch(clickBySpace(title));
  };
  return (
    <Page className={styles.announces} contentClassName={styles.announcesContainer}>
      {list ? (
        <>
          <Typography className={styles.title} variant="h4">
            Анонсы
          </Typography>
          {list.map((props) => (
            <AnnouncementListItem key={props.event_id} onClickLink={handleClickSpace} {...props} />
          ))}
        </>
      ) : (
        // TODO Added announces skeleton
        <Skeleton.ArchiveListItem />
      )}
    </Page>
  );
});
