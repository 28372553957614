import { FC, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { IconButton, Icons, Divider, Tabs, Tab } from '@beeline/lk-ui';

import { Popup } from '../Popup';
import { RootState } from ':store';

import { Questions } from './components/Questions';
import styles from './styles.module.scss';

export interface IEventPopupProps {
  className?: string;
  onClose: () => void;
  targetElement?: string;
}

/**
 * @param root0
 * @param root0.onClose
 * @param root0.targetElement
 * @param root0.className
 * @description Pop up component of chat and questions
 */
export const BroadcastingPopup: FC<IEventPopupProps> = ({ onClose, targetElement, className }) => {
  const { current } = useSelector((state: RootState) => state.event);

  const [tabID, setTabID] = useState(1);

  const makeTab = (title: string, id: number) => (
    <Tab key={id} label={title} onClick={() => setTabID(id)} selected={tabID === id} value={id} />
  );

  const hideChat = tabID !== 1;
  const chat = useMemo(() => {
    const className = cn(styles.chatBody, {
      [styles.hide]: hideChat,
    });
    return <iframe className={className} src={current?.rocket_chat_link || ''} title="Beeline rchat" />;
  }, [current?.rocket_chat_link, hideChat]);

  const questionsBlock = useMemo(() => <Questions />, []);

  const namespaseID = 'eventPopup'; // TODO for bind modal dialog

  const questionsEnabled = current?.ask_question || false;

  return (
    <div className={cn(namespaseID, styles.popup, styles.broadcastingPopup, className)} id={namespaseID}>
      <div className={styles.popupHeader}>
        <Tabs className={styles.tabs}>
          {makeTab('Общий чат', 1)}
          {questionsEnabled && makeTab('Вопросы спикерам', 2)}
        </Tabs>
        <div className={styles.closeButton}>
          <IconButton className={styles.icon} iconName={Icons.Close} onClick={onClose} size="large" />
          <Divider />
        </div>
      </div>
      {chat}
      {tabID === 2 && questionsBlock}
    </div>
  );
};

/**
 * @param root0
 * @param root0.onClose
 * @param root0.targetElement
 * @param root0.className
 * @description Pop up component of questions
 */
export const EventPopup: FC<IEventPopupProps> = ({ onClose, targetElement, className }) => {
  const questionsBlock = useMemo(() => <Questions />, []);

  const namespaseID = 'eventPopup'; // TODO for bind modal dialog

  return (
    <Popup
      className={cn(namespaseID, styles.popup, styles.eventPopup, className)}
      id={namespaseID}
      onClose={onClose}
      targetElementId={targetElement}
    >
      <Tabs className={styles.tabs}>
        <Tab label="Вопросы спикерам" selected value={2} />
      </Tabs>
      {questionsBlock}
    </Popup>
  );
};
