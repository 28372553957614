import { configureStore } from '@reduxjs/toolkit';

import auth from './auth';
import event from './event';
import archive from './archive';
import announce from './announce';

export const store = configureStore({
  reducer: {
    auth,
    event,
    archive,
    announce,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
