import { createSlice } from '@reduxjs/toolkit';

import * as thunks from './thunks';

import { AnnounceStore } from ':types/store';

const initialState: AnnounceStore = {
  current: null,
};

export const event = createSlice({
  name: 'announce',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(thunks.getAnnounceList.fulfilled, (state, action) => {
      if (action.payload) {
        state.current = action.payload;
      }
    });
  },
});

export const { getAnnounceList } = thunks;

export default event.reducer;
